<template>
	<footer>
		<div class="footer-element">
			<div class="row">
				<div class="columns column3">
					<img
						src="~assets/images/logo-ogygia-suites.svg"
						loading="lazy"
						alt="Ogygia Suites"
						class="footer-logo"
					/>
				</div>
				<div class="columns column3">
					<h3>{{ $t('contact') }}</h3>
					<p>
						<a
							v-if="defaults[locale].website.phone"
							:href="`tel:${$phoneRaw(defaults[locale].website.phone)}`"
						>
							<span>{{ defaults[locale].website.phone }}</span> </a
						><br v-if="defaults[locale].website.phone" />
						<a :href="`mailto:${defaults[locale].website.email}`"
							><span>{{ defaults[locale].website.email }}</span></a
						>
					</p>
					<social-media theme="light" :socials="socials" />
				</div>
				<div class="columns column3">
					<h3>{{ defaults[locale].website.hotelName }}</h3>
					<p>
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<br />
						<span>{{ defaults[locale].website.country }}</span>
						<br />
					</p>
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerNavigate') }}</h3>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="columns column12">
					<hr />
				</div>
			</div>
		</div>
		<div class="footer-element author">
			<div class="row">
				<div class="columns column12 align-center">
					<p class="align-left">
						<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer"> Hotel Website Design </a>
						by:
						<a :href="$t('siteByUrl')" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
							Porter
						</a>
					</p>
					<p class="align-right">
						<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
							<nuxt-link :to="item.filename">
								{{ item.header }}
							</nuxt-link>
							<span v-if="idx < bottomfooter[locale].length - 1"> | </span>
						</span>
					</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
/* Footer */
footer {
	background: $footer-background-color;
	color: #fff;
	padding: 75px 0 10px;
	font-size: 15px;

	hr {
		background: #f4f0e7;
		margin: 40px 0 0;
	}

	a {
		color: $footer-link-color;

		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}

	h3 {
		color: #fff;
		margin: 0 0 30px;
	}
}

.author {
	padding: 15px 0 10px;
	font-size: 12px;

	.column12 {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

.footer-logo {
	max-width: 130px;
	margin: 0 0 30px;
}

@media (max-width: 920px) {
	footer {
		.columns {
			margin: 20px auto;
		}

		&.author {
			padding: 10px 0 100px;

			.columns {
				margin: 0;
				flex-flow: wrap column-reverse;
			}

			p {
				width: 100%;
				margin: 0 0 5px;
				text-align: center;
			}
		}
	}
}

@media (max-width: 680px) {
	footer {
		text-align: center;
	}
}
</style>
