<template>
	<section class="promotions background-light main-content align-center">
		<div class="row row-header align-center">
			<h2 class="subtitle">
				{{ subtitle }}
			</h2>
			<h2>
				{{ header }}
			</h2>
			<div v-parse-links v-html="content" />
		</div>
		<div class="row promotions-row flex-row slide-row">
			<client-only>
				<bc-carousel
					:itemsToShow="1"
					:breakpoints="breakpoints"
					:pagination-position="'bottom-overlay'"
					:navigation-enabled="true"
					:space-padding="20"
					:scroll-per-page="false"
					:loop="true"
					class="carousel"
				>
					<bc-slide v-for="room in items" :key="room.ID" class="slick-item">
						<nuxt-link :to="localePath(room.filename, $i18n.locale)">
							<div class="slick-slide-content">
								<h3>{{ room.header }}</h3>
								<span>
									{{ room.button }}
									<font-awesome-icon icon="fa-light fa-arrow-right-long" size="1x" />
								</span>
							</div>
							<picture>
								<source
									v-if="room.imageOverviewPortraitWebp"
									:srcset="room.imageOverviewPortraitWebp"
									type="image/webp"
								/>
								<source :srcset="room.imageOverviewPortrait" />
								<img
									loading="lazy"
									:src="room.imageOverviewPortrait"
									:alt="room.imageOverviewPortraitAlt"
								/>
							</picture>
						</nuxt-link>
					</bc-slide>
					<template #addons>
						<bc-pagination />
					</template>
				</bc-carousel>
			</client-only>
		</div>
	</section>
</template>

<script setup>
defineProps({
	items: { type: Array, default: () => [] },
	subtitle: { type: String, default: '' },
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});

const breakpoints = {
	600: {
		itemsToShow: 2,
	},
	800: {
		itemsToShow: 3,
	},
	1024: {
		itemsToShow: 4,
	},
};
</script>

<style lang="scss" scoped>
.contentblocks {
	background: #bf702a;
}

.row-header {
	max-width: 980px;
}
</style>
