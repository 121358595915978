<template>
	<div>
		<div class="nav-container" :class="{ active: showMenu }">
			<div class="nav-contact">
				<a v-if="defaults[locale].website.phone" :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
					<font-awesome-icon icon="far fa-phone" size="1x" />
					<span>{{ defaults[locale].website.phone }}</span>
				</a>
				<a :href="`mailto:${defaults[locale].website.email}`">
					<font-awesome-icon icon="far fa-envelope" size="1x" />
					<span>{{ defaults[locale].website.email }}</span>
				</a>
				<social-media :socials="socials" />
			</div>

			<ul class="main-nav">
				<li
					v-for="item in topmenu[locale]"
					:key="item.filename"
					:class="{ 'has-dropdown': item.submenu.length }"
				>
					<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
						{{ item.header }}
					</nuxt-link>
					<submenu
						v-if="item.submenu.length"
						:hide-all="!showMenu"
						:item="item"
						:subitems="item.submenu"
						@hide-menu="hideMenu"
					/>
				</li>
				<!-- <li v-if="page" class="lang-nav">
					<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
						<nuxt-link
							class="flag"
							:class="item.language"
							:to="`${item.filename}`"
							@click="hideMenu"
						>
							<span>{{ $t(item.language) }}</span>
						</nuxt-link>
					</div>
				</li> -->
			</ul>
			<div class="close-wrapper">
				<div class="nav-close" @click="hideMenu">
					<font-awesome-icon icon="fa-light fa-times" size="1x" />
				</div>
			</div>
		</div>

		<nav class="main fix-on-scroll" :class="page && page.headingImage ? '' : 'no-slider'">
			<div class="row">
				<div class="columns column12 flex-row">
					<div class="menu-button button" @click="toggleMenu">
						<div class="menu-button-icons">
							<div class="menu-bar" />
							<div class="menu-bar" />
							<div class="menu-bar" />
						</div>
						<span>{{ $t('menu') }}</span>
					</div>
					<button class="logo" aria-label="Back to homepage" @click="handleLogoClick" />

					<div class="nav-buttons">
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="far fa-envelope" size="1x" />
						</a>
						<a
							v-if="defaults[locale].website.phone"
							:href="`tel:${$phoneRaw(defaults[locale].website.phone)}`"
						>
							<font-awesome-icon icon="far fa-phone" size="1x" />
						</a>
						<social-media :socials="socials" />
						<book-button class="button button-cta reserve-now right" aria-label="Reserve now">
							<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
							<span>{{ $t('bookNow') }}</span>
						</book-button>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();
const router = useRouter();

await fetchPage();
await fetchDefaults();

const { data: topmenu } = await useWebsiteFetch('topmenu');

const showMenu = ref(false);

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};

const handleLogoClick = () => {
	if (showMenu.value) {
		hideMenu();
	}

	if (route.name?.startsWith('index')) {
		window.scrollTo(0, 0);
	} else {
		router.push('/');
	}
};
</script>

<style lang="scss" scoped>
/* Navigation */
.logo {
	background: url('~/assets/images/logo-ogygia-suites.svg') no-repeat center center;
	background-size: 168px;
	width: 168px;
	height: 110px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	animation: fade-from-top 1s ease;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

nav.main {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	padding: 45px 0;
	transition: all 0.3s ease-in-out;
	overflow: visible;

	&.scroll,
	&.no-slider {
		padding: 22px;
		background: #c1bbac;

		.logo {
			background: url('~/assets/images/logo-ogygia-suites.svg') no-repeat center center;
			background-size: 108px;
			width: 108px;
			height: 71px;
		}
	}
}

nav .row {
	overflow: visible;
}

.menu-button {
	background: none;
	padding: 0;
	margin: 0;
	font-size: 26px;
	font-weight: 400;
	cursor: pointer;
	text-align: left;
	animation: fade-from-left 0.7s ease;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	opacity: 0.85;
	text-transform: uppercase;
	border: none;
	color: #fff;

	.menu-button-icons {
		width: 46px;
		margin: 0 6px 0 0;

		div {
			margin: 9px 0;
			width: 36px;
			height: 2px;
			background: #fff;
			transition: all 0.2s ease-in-out;

			&:first-child {
				width: 46px;
			}

			&:last-child {
				width: 26px;
			}
		}
	}

	&:hover {
		opacity: 1;
		background: none;

		.menu-button-icons {
			div {
				width: 46px;
			}
		}
	}
}

nav .flex-row,
.nav-buttons {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.nav-buttons {
	gap: 14px;
	color: #fff;
	animation: fade-from-right 0.7s ease;

	a {
		color: #fff;
		text-decoration: none;
		margin: 0;
		opacity: 0.85;

		&.button,
		&:hover {
			opacity: 1;
		}
	}
}

.reserve-now {
	svg {
		margin: 0 6px 0 0;
	}
}

.nav-container {
	background: rgb(193 187 172 / 75%);
	backdrop-filter: blur(17px);
	padding: 30px 50px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	transform: translate(0, 100%) matrix(1, 0, 0, 1, 0, 0);
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
}

.nav-container.active {
	transform: matrix(1, 0, 0, 1, 0, 0);
	opacity: 1;
}

.lang-nav {
	display: none;
	list-style: none;
	text-align: left;
	float: left;
	width: 50%;
	line-height: 26px;
	padding-top: 40px;

	a {
		color: #fff;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		font-size: 16px !important;
		gap: 5px;

		&.router-link-exact-active,
		&:hover {
			color: $cta-color;
		}

		&::before {
			display: none !important;
		}
	}

	img {
		max-width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}
}

.lang-select {
	padding: 12px 15px;
	border-right: 1px solid #f4f0e7;
	cursor: pointer;
	position: relative;
	text-transform: uppercase;

	i {
		font-size: 12px;
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: center space-between;
		color: #000;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		margin: 2px 0;

		img {
			width: 16px;
			height: 16px;
			margin: 0 3px 0 0;
		}
	}
}

.mobile-lang-nav {
	margin: 40px 0 0;
	font-size: 14px;
	display: none;
}

.mobile-lang-nav img {
	max-width: 16px;
	margin: 0 10px 0 0;
}

.main-nav .mobile-lang-nav a {
	width: 100%;
	display: block;
	font-size: 16px;
	line-height: 28px;
}

/* Fullscreen Nav */
.main-nav {
	list-style: none;
	min-width: 26%;

	a {
		color: #fff;
		font-size: 34px;
		line-height: 62px;
		font-weight: 400;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;

		&::before {
			content: '';
			display: block;
			width: 0;
			height: 2px;
			margin: 0 12px 0 0;
			background: #fff;
			transition: width 0.3s ease-in-out;
		}

		&:hover,
		&.router-link-exact-active {
			color: #fff;

			&::before {
				width: 42px;
			}
		}
	}
}

.nav-contact {
	align-self: flex-end;
}

.nav-contact > a {
	width: 100%;
	color: #fff;
	display: block;
	font-weight: 300;
	margin: 0 0 15px;
}

.nav-contact > a i {
	margin: 0 10px 0 0;
}

.nav-contact .socials {
	margin: 20px 0 0;
}

.close-wrapper {
	align-self: flex-start;
}

.nav-close {
	color: #fff;
	cursor: pointer;
	font-size: 60px;
	padding: 10px;
}

/* Breadcrumbs */
.breadcrumbs ul {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin: 0 0 75px;
}

.breadcrumbs li {
	margin: 0 10px 0 0;
}

.breadcrumbs i {
	font-size: 11px;
}

.breadcrumbs a {
	color: #474747;
}

.breadcrumbs a.active {
	color: #d9bd8b;
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-width: 920px) {
	.menu-button {
		justify-content: center;
	}

	.lang-nav {
		display: block;
	}

	.close-wrapper {
		align-self: flex-start;
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		float: left;
		margin-top: 30px;
	}

	.main-nav {
		a {
			font-size: 22px;
			line-height: 44px;
		}
	}
}

@media (max-width: 600px) {
	.nav-container {
		padding: 10px 25px 25px;
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		width: 100%;
	}

	nav.main {
		.flex-row {
			padding: 0;
		}
	}

	nav.main.scroll,
	nav.main.no-slider {
		padding: 18px 6px;
	}

	.contact-info {
		margin: 25px auto 0;
	}

	.lang-nav {
		margin-top: 0;
	}

	.logo {
		left: -25px;
	}

	.nav-buttons {
		gap: 2px;

		a {
			width: 44px;
			height: 44px;
			line-height: 44px;
			background: $cta-color;
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			justify-content: center;

			&.reserve-now {
				width: 40px;
				padding: 10px 0;

				svg {
					margin: 0;
				}

				span {
					display: none;
				}
			}
		}

		button {
			svg {
				margin-right: 0;
				font-size: 16px;
			}
		}
	}
}
</style>
